.Dashboard {
  width: 100%;
  height: 100vh;
  padding: 3rem;
  background: url('../../assets/images/HalfPin.png') no-repeat;
  background-size: 20%;
  background-position: 0 0;

  .dashboard_link_container {
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: start;
    align-content: space-between;
    gap: 2.5rem;
  }

  .dashboard_link {
    width: 300px;
    display: flex;
    align-items: center;
    flex-direction: column;
    row-gap: 1em;
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }

  .dashboard_link img {
    height: 30px;
  }

  .media_button {
    .media_button_image {
      transition: all 0.2s ease-in-out;
      opacity: 0.5;
    }
    &:hover {
      .media_button_image {
        opacity: 1;
        transform: scale(1.2);
      }
    }
  }
}

.Placeholder {
  width: 100%;
  height: 30rem;
  display: grid;
  grid-template-columns: 0.66fr 1fr;
  justify-content: space-between;

  .intro-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .code-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    background: url('../../assets/images/Map.png') no-repeat;
    background-size: 100%;
    background-position: center right;
    code {
      background: #121213;
      padding: 1em;
      color: white;
      border-radius: 10px;
    }
  }
}

.Container {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
}

.MentaportCard {
  display: flex;
  flex-direction: column;
  height: 10rem;
  width: 100%;
  min-width: 19.5rem;
  border: 1px solid #ebebeb !important;
  border-radius: 10px;
  background: white;
  padding: 1.5rem;
  gap: 1rem;

  &.ApiKeys {
    gap: 1rem;
  }

  &.DocLinks {
    .Content {
      justify-content: center;
    }
  }
  &.ShowCase {
    align-items: center;
    justify-content: center;
    padding: 0rem;
    background: url('../../assets/images/ShowCase.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    cursor: pointer;
  }
}

.Content {
  width: 100%;
  display: flex;
  align-items: center;
  height: 100%;
}

.Title {
  font-weight: 600;
}

.Link {
  font-weight: 500;
}

.KeyEntry {
  width: 100%;
  display: flex;
  gap: 0.5rem;
}

@media (max-width: 1130px) {
  .Container {
    grid-template-columns: 1fr;
  }
  .Placeholder {
    grid-template-columns: 1fr;
  }
}
