.LineChartContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.4);
    padding: 0.5rem 0;
    border-radius: 0.625rem;
    position: relative;
    z-index: inherit;
  
    .EmptyMessage {
      font-size: 1rem;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: 0.02375rem;
      text-align: center;
    }
  
    @media only screen and (max-width: 720px) {
      .EmptyMessage {
        font-size: 1.5rem;
      }
    }
  }
  
  .CustomTooltip {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 0.5rem;
    width: 100%;
    min-width: 120px;
    min-height: 50px;
    background: rgba(255, 255, 255, 0.7);
    border: 1px solid lightgray;
    padding: 0.6rem 1rem;
    border-radius: 0.625rem;
    position: relative;
    z-index: inherit;
    color: gray;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.02375rem;
    backdrop-filter: blur(4px); 
    text-shadow: 10rem;

    &.Dark {
      background: rgba(0, 0, 0, 0.6);
    }
  
    .TooltipTitle {
      font-size: 1rem;
      font-weight: 500;
    }
  
    .TooltipValue {
      font-size: 1.1rem;
      font-weight: 600;
    }

    .TooltipDescription {
      font-size: 0.8rem;
      font-weight: 400;
    }
  }
  