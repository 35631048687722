.Button {
    border-radius: 0.8rem;
    font-weight: 400;

    &.Warning {
        background: #FFE092;
        &:hover {
            background: #fdcc4d;
        }
    }

    &.Reported {
        height: 28px;
        padding: 0.3rem;
    }
}

.InputContainer {
    border-radius: 0.8rem;
    border: 1px solid #C7C7CC;
}