.NewContractPage {
  width: 100%;

  .Container {
    display: flex;
    flex-direction: column;
    padding: 1rem 3rem;

    &.Left {
      width: 70%;
      padding-left: 20%;
      gap: 1rem;
      justify-content: center;
      align-items: flex-start;
    }

    &.Right {
      width: 30%;
      gap: 1rem;
      justify-content: start;
      height: 100%;
      padding-top: 10rem;
    }

    .NewContractPageInputTitle {
      font-weight: 450;
      font-size: 1.15rem;
      line-height: 1.5rem;
      margin-bottom: .7rem;
    }

    .NewContractPageInputTitle1 {
      font-weight: 450;
      font-size: 1.15rem;
      line-height: 1.5rem;
      margin-bottom: .52rem;
    }
    .NewContractPageInputTitleSmall {
      font-weight: 350;
      font-size: 0.7rem;
     
    }
    .Placeholder {
      padding: 0.5rem;
      background-color: grey;
      height: 6rem;
      width: 100%;
    }

    .Title {
      color: #000;
      font-size: 3.125rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      opacity: 0.2;
    }

    .Definition {
      font-style: normal;
      line-height: normal;
      color: #000;
      opacity: 0.2;
    }

    .Divider {
      border-top: 1px solid lightgray;
      align-self: flex-start;
      width: 100%;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }

    .ActionsSection {
      display: flex;
      align-self: flex-start;
      gap: 0.5rem;
    }
  }
}
