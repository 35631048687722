.chakra-container::-webkit-scrollbar {
  width: 0.5rem;
}
.chakra-container::-webkit-scrollbar-corner {
  background: transparent;
}
.chakra-container::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 1.25rem;
}
.manage-keys-page {
  height: 100%;
  width: 100%;
}
.css-15wwp11-MuiTableHead-root {
  th {
    font-size: 1rem;
    font-weight: 700;
    text-align: left;
    border: 0;
  }
}
.css-apqrd9-MuiTableBody-root {
  th {
    font-size: 1rem;
    border: 0;
  }
  td {
    font-size: 1rem;
    border: 0;
  }
}

.MuiTableContainer-root {
  background: transparent !important;
}

tr .rowTools {
  opacity: 0.2;
  padding-left: 0;
  button {
    margin: 5px !important;
  }
}

tr:hover {
  .rowTools {
    opacity: 1;
  }
}

.ActionsSection {
  margin:auto;
  align-items: center;
  margin-top: 40px;

}