.SelectContainer {
    border-radius: 12px;
    &::placeholder {
        font-size: 12px; /* Adjust the font size as needed */
    }
}

.Button {
    border-radius: 0.8rem !important;
    font-weight: 400;

    &.Option {
        font-size: 13px !important;
        font-weight: 400 !important;
        line-height: 2.5;
        height: min-content;
    }

    &.Warning {
        background: #FFE092;
        &:hover {
            background: #fdcc4d;
        }
    }

    &.Green {
        background: #4dfd9f;
        &:hover {
            background: #00c85d;
        }
    }

    &.Red {
        background: #ff7979;
        &:hover {
            background: #c21a1a;
        }
    }

    &.Disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }
}