.OptionListTitleContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.OptionListTitle {
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.5rem;
  margin-bottom: 1.5rem;
}
.OptionListValue {
  font-weight: 80;
  font-size: 0.82rem;
  line-height: 1rem;
}
.OptionList {
  gap: 1.5rem;
  grid-template-columns: 1fr 1fr;
  .OptionBox {
    max-height: 61px;
    box-sizing: border-box;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1.5rem;
    background: linear-gradient(#F0F6FF, #E8EBF1);
    border-radius: 0.625rem;
    cursor: pointer;
    .OptionBoxLabel {
      gap: 0.5rem;
      align-items: center;
      .OptionBoxIcon {
        width: 20px;
      }
    }
  }
}
@media (max-width: 1050px) {
  .OptionList {
    grid-template-columns: 1fr;
  }
}
